<template>
  <v-btn
    color="color3Text color3--text"
    :class="btnClass"
    fab
    small
    @click.stop="print"
  >
    <v-icon small>fas fa-print</v-icon>
  </v-btn>
</template>

<script>
import flatten from '@/helpers/ArrayFlatten'
import PoolPlayOptionDescriptor from '@/classes/PoolPlayOptionDescriptor'
import RouteMixin from '@/Mixins/RouteMixin'
import { mapGetters } from 'vuex'
import { firstBy } from 'thenby'
const pdfMake = () => import('pdfmake/build/pdfmake.js')
const pdfFonts = () => import('pdfmake/build/vfs_fonts.js')

// http://pdfmake.org/playground.html

export default {
  mixins: [RouteMixin],
  props: ['pools', 'list', 'btnClass'],
  computed: {
    ...mapGetters(['tournament', 'getDivision', 'getRound', 'getTeam']),
    division () {
      return this.getDivision(this.divisionId)
    },
    round () {
      return this.getRound(this.dayId)
    },
    listContent () {
      const content = []
      const teams = this.getTeams().map(t => {
        return [t.team, t.pool, t.courts]
        // return [t.team, t.pool, t.courts, {qr: this.getTeamURL, fit: 50}]
      })
      const table = {
        widths: ['*', 'auto', 'auto'],
        body: [
          ['Team', 'Pool', 'Court'],
          ...teams
        ]
      }
      content.push({ table: table, style: 'mt' })

      return {
        header: { text: `${this.tournament.name} - ${this.division.name} ${this.round.name}`, style: 'header', alignment: 'center' },
        content: content
      }
    },
    content () {
      const pools = this.poolId ? this.pools.filter(f => f.id === this.poolId) : this.pools
      const content = pools.map(p => JSON.parse(this.makeSubs(p))).filter(f => f)

      if (content.length > 1) {
        content.forEach((p, i) => {
          if (i + 1 < content.length) {
            p[p.length - 1].pageBreak = 'after'
          }
        })
      }
      return { content: flatten(content) }
    },
    subVars () {
      return this.poolId && this.getSubVars(this.poolId)
    },
    pdfDefinition () {
      var dd = {
        styles: {
          header: {
            fontSize: 18,
            bold: true
          },
          subheader: {
            fontSize: 15,
            bold: true
          },
          quote: {
            italics: true
          },
          small: {
            fontSize: 8
          },
          my: {
            margin: [0, 20, 0, 20]
          },
          mt: {
            margin: [0, 20, 0, 0]
          }
        }
      }

      return Object.assign(dd, this.list ? this.listContent : this.content)
    }
  },
  methods: {
    getTeams () {
      var teams = flatten(this.round.flights[0].pools.map(pool => {
        return pool.teams.map(team => {
          const t = this.getTeam(team.teamId)
          return {
            pool: pool.name,
            team: t.name,
            teamId: team.teamId,
            courts: pool.courts
          }
        })
      }))

      return teams.sort(firstBy('team'))
    },
    makeSubs (pool) {
      let base = pool && pool.setting && pool.setting.pdfMake
      if (!base) return null
      const vars = this.getSubVars(pool.id)
      vars.forEach(v => {
        base = base.replace(new RegExp(v.text, 'g'), v.val)
      })
      return base
    },
    getSubVars (poolId) {
      const params = this.getParams(poolId)
      var results = [
        { text: '{division}', val: params.division.name },
        { text: '{poolName}', val: params.pool.name },
        { text: '{courts}', val: this.$options.filters.formatArray(params.pool.courts) || '' },
        { text: '{poolURL}', val: this.getPoolURL(params) }
      ]
      const courts = params.pool.courts ? params.pool.courts.split(',') : []
      const c1 = courts.length ? courts[0] : ''
      const c2 = courts.length > 1 ? courts[1] : ''
      results.push(
        { text: '{court1}', val: c1 },
        { text: '{court2}', val: c2 }
      )
      let i = 0
      params.pool.teams.forEach((t) => {
        const team = this.getTeam(t.teamId)
        results.push({ text: `{team${i++ + 1}}`, val: team.name })
      })
      while (i < 8) {
        results.push({ text: `{team${i++ + 1}}`, val: '' })
      }
      i = 3
      while (i < 8) {
        var s = params.pool.settings.find(f => f.poolsOf === i)
        if (s) {
          const d = new PoolPlayOptionDescriptor(s)
          results.push({ text: `{matchDescription${i++}}`, val: d.description })
        } else {
          results.push({ text: `{matchDescription${i++}}`, val: '' })
        }
      }

      return results
    },
    getParams (poolId) {
      var params = flatten(this.tournament.divisions.map(div => {
        return div.days.map(da => {
          var pool = da.flights && da.flights[0] && da.flights[0].pools.find(p => p.id === poolId)
          return pool && {
            tournament: this.tournament,
            division: div,
            day: da,
            pool: pool
          }
        })
      }))
      return params.find(f => f)
    },
    getPoolURL (params) {
      const a = document.createElement('a')
      a.href = this.$router.resolve({ name: 'pool-sheet', params: { dayId: params.day.id, divisionId: params.division.id, poolId: params.pool.id, tournamentId: params.tournament.id } }).href
      return a.href
    },
    getTeamURL (id) {
      const a = document.createElement('a')
      a.href = this.$router.resolve({ name: 'division-team', params: { tournamentId: this.tournament.id, teamId: id } }).href
      return a.href
    },
    print () {
      Promise.all([pdfMake(), pdfFonts()])
        .then(data => {
          const p = data[0]
          const f = data[1]
          window.pdfMake.vfs = f.pdfMake.vfs

          p.createPdf(this.pdfDefinition).print()
        })
    }
  }
}
</script>

<style scoped>

</style>
